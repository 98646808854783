import React, {useState, useEffect} from "react";
import {Modal, Button, Image, Table} from "react-bootstrap";
import tickIcon from '../images/tick-icon.png';
import crownIcon from '../images/crown.png';
import freeIcon from '../images/free.png';
import './PremiumFeatures.css';

export default function PremiumFeatures(){

  const [showSupportedDevicesModal, setShowSupportedDevicesModal] = useState(false);
  const [paymentPrice, setPaymentPrice] = useState("");

  const handleShowSupportedDevices = () => setShowSupportedDevicesModal(true);
  const handleCloseSupportedDevices = () => setShowSupportedDevicesModal(false);

  const fetchPaymentDetails = () => {
      fetch("/api/payment/details")
      .then(response => response.status===200 ? response.json() : [])
      .then(result =>{
        setPaymentPrice(result.price);
      })
  }

  useEffect(
    () => {  
      fetchPaymentDetails();
    }
  ,[]);

  return (
    <div className="premium-features">

      <Modal show={showSupportedDevicesModal} onHide={handleCloseSupportedDevices} centered size="md" className="supportedDevicesModal">
        <Modal.Header closeButton>
          <Modal.Title>Supported Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <li>Fenix (5 Plus, 5s Plus, 5x, 5x Plus, 6 Pro, 6s Pro, 6x Pro)</li>
          <li>ForeRunner (245 Music, 255, 255 Music, 255 S, 255 S Music, 645 Music, 920XT, 945, 945 LTE, 955)</li>
          <li>Approach 62</li>
          <li>Descent MKI/II/IIs</li>
          <li>D2 (Air, Air X10, Charlie, Delta, Delta px, Delta S, Mach 1)</li>
          <li>Captain Marvel, First Avenger</li>
          <li>Venu (base model, d, Square Music, 2, 2s, 2s Plus)</li>
          <li>Vivoactive (3 Music, 3 Music LTE, 4, 4s)</li>
        </Modal.Body>
      </Modal>

      <p>
        Install the app from the <a target="_blank" rel="noreferrer" href="https://apps.garmin.com/en-US/apps/a64d3253-f976-405b-afdf-eef2e5dca6ec">Garmin App Store</a> to 
        start your <strong>free 30 day trial</strong> of the Premium version. After 30 days, either continue with the free version or purchase 
        the Premium version for a one-off payment of <strong>{paymentPrice}</strong> to retain access to <strong>full world maps</strong>. See our feature comparison below to compare
        the Free and Premium versions. Most features other than background maps are free.
        
      </p>
      <Table striped bordered hover className="feature-table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Free
              <img src={freeIcon} alt="free icon" title="Free User" />
              <span className="price">-</span>
            </th>
            <th>Premium
              <img src={crownIcon} alt="crown icon" title="Premium User" />
              <span className="price">{paymentPrice !=null ? paymentPrice : ""}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Support for Devices Without Native Mapping
              <div className="description">Support for a wide range of devices including those without on-device mapping</div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Advanced Routing
              <div className="description">Upload routes to mappily.info then download onto the watch using
               the device menus and go navigate. Directional arrows clearly show your direction of travel. 
               Zoom in and out as required. Route display orientates to your direction of travel (or North if you prefer).</div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>Offline Route Navigation
              <div className="description">No data connection is required to navigate your route
              </div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Activity Recording
              <div className="description">
                Record various activity types as you normally would. Pause recording at any time. Saved activities will
                seamlessly sync to Garmin Connect.
              </div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Activity Auto-Lap and Auto-Pause
              <div className="description">
                Customize auto-lap distance and enable auto-pause as required.
              </div>  
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Off-Route Warnings
              <div className="description">
                The app detects if you go off course and alerts you with vibration and/or audible alerts.
              </div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              On-Screen Compass and Map Scale
              <div className="description">
                The map/route screen has a compass and scale to aid navigation
              </div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Two Dedicated Data Screens
              <div className="description">
              Choose from a comprehensive set of pace, speed, distance, power, time, ascent/descent and heart rate fields
              </div>
            </td>
            <td className="yes"><Image src={tickIcon}/></td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Full World Maps Based on Open Street Maps Data
              <div className="description">
              Full vector-based colour maps with whole-world coverage, including all roads, paths, rivers and more.
              Intuitive orientation of maps to your direction of travel (or North-up if you prefer)
              </div>
            </td>
            <td className="no">-</td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Offline Maps
              <div className="description">
              Download the map sections covering your route via the on-device menus and navigate without
              a data connection. This feature is currently in Beta.
              </div>
            </td>
            <td className="no">-</td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Live Route Profile Data Field
              <div className="description">
                This shows the full elevation profile of your loaded route
                and your position along it.  Please check
                <Button variant="link" className="buttonLinkAdjust" onClick={()=> handleShowSupportedDevices()} >Supported Devices</Button>
                because a few don't have enough memory.
              </div>
            </td>
            <td className="no">-</td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
          <tr>
            <td>
              Guaranteed Access to all new Features
              <div className="description">
              We're constantly adding features and you get early access to any beta features we build.
              </div>
            </td>
            <td className="no">-</td>
            <td className="yes"><Image src={tickIcon}/></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}