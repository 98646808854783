import React, {} from "react";
import {Container, Button, Row, Col, Card, CardDeck, ListGroup, ListGroupItem, Image} from "react-bootstrap";
import PremiumFeatures from "./PremiumFeatures";
import logo from './logo.png';
import Icon from './icon.png';
import CookieConsent from "react-cookie-consent";
import './registration.css';
import mapRoundAboutScreenshot from '../images/screenshot-map-roundabout-fenix.jpg';
import mapRoadsScreenshot from '../images/buckingham-palace.png';
import mapFirstDataScreenScreenshot from '../images/screenshot-data-firstscreen.jpg';
import navigationScreenshot from '../images/navigation.png';
import downArrow from '../images/down-arrow.png';
import bullet from '../images/bullet.png';
import watchAnimation from '../images/example-animated.gif';
import instagramLogo from '../images/instagram.jpg';
import ConnectIcon from '../images/connect_icon.png';
import osmMap from '../images/central-tokyo-osm.png';
import centralTokyo from '../images/central-tokyo.png';
import page1 from '../images/page-1.png';
import page2 from '../images/page-2.png';
import page3 from '../images/page-3.png';

export default function Public(){
    
    return (

      <Container fluid className="d-flex flex-column" id="front-page">
        <Row lg="8" className="header">
          <Col>
          <a href="/public"><img src={logo} className="mappily-logo" alt="Mappily"></img></a>
          </Col>
          <Col lg="4">
          <Button variant="link" href="/faqs" className="faq">FAQs</Button> 
          <Button variant="link" href="https://forum.mappily.info" className="support">Support</Button> 
          <Button className="buy-button" variant="warning" href="/register">Buy the App</Button>
          <Button variant="link" className="signUp" href="/register">Sign Up</Button> 
          <Button variant="outline-primary" className="login" href="/login">Log In</Button>
          </Col>
        </Row>
        <Row className="justify-content-lg-center flex-fill flex-grow" lg="1">
          <Col className="d-flex flex-column flex-grow">
          {/* {<Alert variant="info">
          We will have a short maintenance window tonight (18th June) at 22:00GMT for a server upgrade. There is a possibility of a short outage during that time.
          </Alert> } */}
          </Col>
        </Row>
        <Row className="justify-content-lg-center flex-fill flex-grow content featured-row" lg="1">
          <Col className="d-flex flex-column flex-grow">
            <Card className="cards-top">
              <Row lg="3" sm="2">
                <Col lg="4" sm="8" xs="7" className="featured-image-col">
                  <span className="helper"></span>
                  <Image className="featured-image" src={watchAnimation} />
                </Col>
                <Col lg="2" sm="4" xs="3" className="side-images">
                  <Row>
                    <Col>
                      <Image className="side-image first" src={page1} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <strong>Page 1</strong> - Map
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Image className="down-arrow" src={downArrow} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Image className="side-image" src={page2} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <strong>Page 2</strong> - Four Data Fields
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Image className="down-arrow" src={downArrow} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Image className="side-image" src={page3} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Page 3</strong> - Four Data Fields
                    </Col>
                  </Row>
                </Col>
                <Col lg="5" sm="12" xs="12" className="featured-text">
                  <h1 className="main-heading">Live Maps on your Garmin Device</h1>
                  <Card.Text>
                    <p>We were frustrated with a lack of maps on some Garmin devices and thought we could fix that.</p>
                    <p>As trail runners ourselves, we wanted it all: routing and maps, combined with all the data fields you expect from a running app.</p>
                    <p><strong>Mappily</strong> is the result. Experience live colour maps on Garmin watches (including most ForeRunner, Venu and Vivoactive), 
                    which scale seamlessly and move as you do.</p>
                  </Card.Text>
                  <ListGroup className="list-group-flush">
                            <ListGroupItem><Image className="bullet" src={bullet} />Brings mapping to devices without native mapping</ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} /><strong>Offline Maps</strong></ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} />Maps which orient and move with you based on Open Street Maps data</ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} />Plot routes and follow them during your recorded workouts</ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} />Zoom in and out</ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} />Multiple screens containing all your favourite fields, including Pace, Distance, Time and more</ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} />Off-route warnings, auto-lap, auto-pause</ListGroupItem>
                            <ListGroupItem><Image className="bullet" src={bullet} /><a href='#feature-comparison-section'>Compare Free and Premium Versions</a></ListGroupItem>
                    </ListGroup>
                    <span className="register">
                      <h3>Register now and try Premium for 30 days on us</h3>
                      <span className="register-container"><Button className="register-button" href="/register">Sign Up For Free</Button></span>
                      <div>*No payment details required. We will <strong>not</strong> bill you after 30 days. The free version is always free.</div>
                      <span>Got any Questions? Read our <a href="/faqs">FAQs</a></span>
                    </span>
                </Col>
              </Row>
              <Row>
              <Col lg={12}>
                <Image className="down-arrow" src={downArrow} />
              </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-lg-center flex-fill flex-grow content" lg="1">
          <Col className="d-flex flex-column flex-grow">
              <CardDeck className="cards-feature">
                <Card>
                  <span className="fade-effect">
                    <Card.Img variant="top" className="topmost" src={centralTokyo} />
                    <Card.Img variant="top" className="bottommost" src={osmMap} />
                    <span class="osm-overlay-text">Central Tokyo</span>
                  </span>
                  <Card.Body>
                    <Card.Title>Live Open Street Maps</Card.Title>
                    <Card.Text>Live full-screen vector maps directly on your device*</Card.Text>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>Updates in real-time as you move</ListGroupItem>
                      <ListGroupItem>Zoom in and out</ListGroupItem>
                      <ListGroupItem>Orients to your direction of travel</ListGroupItem>
                      <ListGroupItem><strong>Full World Map Coverage</strong> (Excl. Antarctic/Arctic if we're being completely honest)</ListGroupItem>
                      <ListGroupItem><strong>Experimental</strong> - Download the map sections for your chosen route for genuine offline mapping</ListGroupItem>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">* Data connection required unless you download the maps</small>
                  </Card.Footer>
                </Card>
                <Card>
                  <Card.Img variant="top" src={navigationScreenshot} />
                  <Card.Body>
                    <Card.Title>Routes and Navigation</Card.Title>
                    <Card.Text>Download your own GPX routes which are clearly overlaid directly on the map</Card.Text>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>Live routes with no data connection*</ListGroupItem>
                      <ListGroupItem>On-device access to all your routes</ListGroupItem>
                      <ListGroupItem>On screen compass aids navigation</ListGroupItem>
                      <ListGroupItem>Numeric map scale</ListGroupItem>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">* Website registration required for route features and data connection required for initial route download</small>
                  </Card.Footer>
                </Card>
                <Card>
                  <Card.Img variant="top" src={mapFirstDataScreenScreenshot} />
                  <Card.Body>
                    <Card.Title>Multiple Activity Fields</Card.Title>
                    <Card.Text>Two data screens each containing four fully customisable fields</Card.Text>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>Choose from various pace, speed, distance, time, ascent/descent and heart rate fields</ListGroupItem>
                      <ListGroupItem>Route Elevation Profile field available on selected watches</ListGroupItem>
                      <ListGroupItem>Instantaneous, 30 second and 60 second average pace fields included</ListGroupItem>
                      <ListGroupItem>Optional Auto-Pause and Auto-Lap</ListGroupItem>
                      <ListGroupItem>Audible and Vibration alerts*</ListGroupItem>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">*Only if your device can vibrate/beep!</small>
                  </Card.Footer>
                </Card>
                <Card>
                  <Card.Img variant="top" src={mapRoadsScreenshot} />
                  <Card.Body>
                    <Card.Title>Device Compatibility</Card.Title>
                    <Card.Text>Compatible with a wide range of devices including:</Card.Text>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>Vivoactive® 3, 3 Music/Music LTE, 3 Mercedes-Benz®, 4 & 4S</ListGroupItem>
                      <ListGroupItem>Forerunner® 245, 245 Music, 645, 645 Music, 745, 935 & 945</ListGroupItem>
                      <ListGroupItem>Fenix® 5 and 6 (All Models) and Chronos</ListGroupItem>
                      <ListGroupItem>Venu™, Venu™ 2, 2 Plus, 3, 3s, Mercedes-Benz, Sq, Sq. Music</ListGroupItem>
                      <ListGroupItem>Approach® S62</ListGroupItem>
                      <ListGroupItem>Descent™ Mk1, Mk2 and Mk2i</ListGroupItem>
                      <ListGroupItem>Captain Marvel & First Avenger</ListGroupItem>
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted"></small>
                  </Card.Footer>
                </Card>
              </CardDeck>
              </Col>
            </Row>
            <Row className="justify-content-lg-center flex-fill flex-grow content" lg="1">
              <Col className="d-flex flex-column flex-grow">
                <Card className="premium-features" id="feature-comparison-section">
                  <h1>Feature Comparison</h1>
                  <PremiumFeatures></PremiumFeatures>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-lg-center flex-fill flex-grow content get-started" lg="1">
              <Col className="d-flex flex-column flex-grow">
                <Card className="get-started align-items-center">
                  <h1>Get Started</h1>
                  <Row>
                    <Col className="step">
                      <ListGroup horizontal> 
                        <ListGroup.Item className="item">
                          <Button variant="outline-primary"  target="_blank" href="https://apps.garmin.com/en-US/apps/a64d3253-f976-405b-afdf-eef2e5dca6ec">
                            <Image src={ConnectIcon}></Image>
                            <Card.Title>1. Install the App</Card.Title>
                            <Card.Text>Download the app from the Garmin App Store</Card.Text>
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item className="item">
                          <Button variant="outline-primary" href="/register">
                            <Image src={Icon}></Image>
                            <Card.Title>2. Register</Card.Title>
                            <Card.Text>Get your free account to start navigating routes</Card.Text>
                          </Button>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row lg="3" className="footer-row">
              <Col>
                <a href="https://www.instagram.com/mappily_official/"><Image src={instagramLogo}></Image></a>
              </Col>
              <Col >
                Contact us at <a href="mailto:user-support@mappily.info">user-support@mappily.info</a> or get help on our <a href="https://forum.mappily.info">support forum</a>
              </Col>
              <Col className="privacy">
                <a href="/privacy">privacy policy</a>
              </Col>
            </Row>  
            <CookieConsent>This website uses cookies to enhance the user experience. See our <a href="/privacy">privacy policy</a> for more details</CookieConsent>
        </Container>
    )
}