import React from "react";
import { useHistory} from 'react-router-dom'
import { useAuth } from "../use-auth.js";
import {Dropdown, Image} from "react-bootstrap";
import './user-profile.css'
import crownIcon from '../images/crown.png';
import menuLines from '../images/menu-lines.png'


export default function UserProfileBadge({user}) {
    let history = useHistory();
    let auth = useAuth();
  
    const onSignout = () => {
      auth.signout(() => history.push("/"));
    }

    const onSignin = () => {
      history.push("/");
    }

    return auth.user ? (
      <span className="user-profile-badge">
        Welcome <span className="username">{auth.user}</span>
        {user!=null && user.isPaid ? 
           (<img src={crownIcon} alt="crown icon" title="Premium User" />)
          :(<span></span>)
        }
        <Dropdown>
          <Dropdown.Toggle id="dropdown-main">
            <Image src={menuLines} alt="menu"></Image>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={onSignout}>Sign out</Dropdown.Item>
            <Dropdown.Item className="separator small" href="https://forum.mappily.info">Support Forum</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <button onClick={onSignout}>
          Sign out
        </button> */}
      </span>
    ) : (
      <span className="user-profile-badge">
        <button onClick={onSignin}>
          Sign in
        </button>
      </span>
    );
  }