import React, {useState, useEffect } from "react";
import {Container, Button, Row, Col} from "react-bootstrap";
import { useAuth } from "../use-auth.js";
// import 'simple-login-form/dist/index.css'
import logo from './logo.png';
import './registration.css';

export default function PasswordChange(){
  
  const [message, setMessage] = useState("");
  const [errorMessage, setError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  let auth = useAuth();

  function displayMessage(message, isError){
    setError(isError ? message : "");
    setMessage(isError ? "" : message);
  }

  let confirmPasswordReset = (e) => {
      e.preventDefault();

      var urlParams = new URLSearchParams(window.location.search);
      var token = urlParams.get('token');

      if(token===null || token.length===0){
        displayMessage("Invalid Token", true);
        return;
      }

      token = token.replace(/\s/g,"+");
      
      if(password.length===0 || password!==passwordCheck){
        displayMessage("Passwords do not match.", true);
        return;
      }

      auth.confirmPasswordReset(token, password)
          .then(result => {

            if(result.success){
              displayMessage("Password Updated successfully. Please click on the top right to log in.", false)
            }
            else{
              displayMessage(result.err, true);
            }
          })
  };

  useEffect(() => {
    if(password!==passwordCheck){
      displayMessage("Passwords do not match.", true)
      setbuttonDisabled(true);
    }
    else{
      displayMessage("");
      setbuttonDisabled(false);
    }
  }, [password, passwordCheck]);

  const handlePasswordChange = (e)=>{setPassword(e.target.value)}
  const handlePasswordCheckChange = (e)=>{setPasswordCheck(e.target.value)}

  return (

    <Container fluid className="d-flex flex-column">
          <Row lg="3" className="header">
            <Col>
            <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
            </Col>
            <Col>
            </Col>
            <Col>
              <Button variant="link" className="login" href="/login">Log In</Button>
            </Col>
          </Row>
          <Row className="justify-content-lg-center content flex-fill flex-grow" lg="4">
            <Col className="d-flex flex-column flex-grow">
            <div className="h-100">
              <div className="central-box">
                <h1>Password Reset</h1>
                <form>
                  <div className="input">
                    <input type="password" placeholder="New Password" name="password" onChange={handlePasswordChange}></input>
                  </div>
                  <div className="input last-input">
                    <input type="password" placeholder="Repeat new Password" name="password-check" onChange={handlePasswordCheckChange}></input>
                  </div>
                  {errorMessage && <div className="message errorMessage">{errorMessage}</div>}
                  {message && <div className="message successMessage">{message}</div>}
                  <div className="bottom">
                    <button disabled={buttonDisabled} onClick={confirmPasswordReset}>Go</button>
                  </div>
                </form>
              </div>
              </div>
            </Col>
          </Row>
      </Container>
  )
}