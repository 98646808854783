import React, {useState, useEffect} from "react";
import { useHistory, useLocation} from 'react-router-dom';
import {Container, Button, Row, Col, Spinner} from "react-bootstrap";
import { useAuth } from "../use-auth.js";
import logo from './logo.png';
import './registration.css';
import {ReCaptcha} from '../components/captcha';
import GoogleLogin from 'react-google-login';

export default function RegistrationPage(){
    
    const [errorMessage, setError] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [buttonDisabled, setbuttonDisabled] = useState(true);
    const [complete, setComplete] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    let location = useLocation();
    let auth = useAuth();
    let history = useHistory();

    let { from } = location.state || { from: { pathname: "/home" } };

    let register = (e) => {
        e.preventDefault();

        setShowSpinner(true);

        if(password.length===0 || password!==passwordCheck){
          setError("Passwords do not match.");
          return;
        }
        
        auth.signup(username, password, token)
            .then(result => {

              if(result.success){
                setComplete(true);
              }
              else{
                setError(result.err);
                setComplete(false);
              }
            })
            .finally( ()=>{
              setShowSpinner(false);
            });   
    };

    useEffect(() => {
      if(password!==passwordCheck){
        setError("Passwords do not match.")
        setbuttonDisabled(true);
      }
      else{
        setError("");
        setbuttonDisabled(false);
      }
    }, [password, passwordCheck]);
  
    const handlePasswordChange = (e)=>{setPassword(e.target.value)}
    const handlePasswordCheckChange = (e)=>{setPasswordCheck(e.target.value)}

    const onVerifyCaptcha = (token) => {setToken(token);};

    const responseGoogle = (response) => {
      //console.log(response);

      auth.signinGoogle(response.tokenId)
            .then(result => {

              if(result.success){
                console.log("signed in User:" + result.user.username);
                history.replace(from);
              }
              else{
                setError(result.err);
              }
      })
    }

    return (

      <Container fluid className="d-flex flex-column" id="signup-page">
            <Row lg="3" className="header">
              <Col>
              <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
              </Col>
              <Col>
              </Col>
              <Col>
                <Button variant="link" className="login" href="/login">Log In</Button>
              </Col>
            </Row>
            <Row className="justify-content-lg-center content flex-fill flex-grow" lg="4">
              <Col className="d-flex flex-column flex-grow">
              <div className="h-100">
                {!complete ? 
                    <div className="central-box">
                      <h1>Sign Up</h1>
                      <form>
                        <div className="input">
                        < input type="text" placeholder="Email Address" name="email" onChange={(e) => setUsername(e.target.value)}></input>
                        </div>
                        <div className="input">
                          <input type="password" placeholder="Password" name="password" onChange={handlePasswordChange}></input>
                        </div>
                        <div className="input last-input">
                          <input type="password" placeholder="Repeat Password" name="password-check" onChange={handlePasswordCheckChange}></input>
                        </div>
                        <ReCaptcha onVerifyCaptcha={onVerifyCaptcha} />
                        {errorMessage && <div className="message errorMessage">{errorMessage}</div>}
                        <div className="bottom">

                          {showSpinner ? 
                          (<Button variant="primary" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            <span className="sr-only">Sending...</span>
                            </Button>) 
                            :
                            (<button disabled={buttonDisabled} onClick={register}>Sign Up</button>)
                            }
                        </div>
                        <div className="consent">
                          By signing up you give us consent to use your personal data for the sole purpose of managing your account. We don't share your details with anyone.
                        </div>
                        <div className="federated-logins">
                          <p>Or</p>
                          <GoogleLogin
                            clientId={window.MAPPILY_CONFIG.GOOGLE_CLIENT_ID}
                            buttonText="Sign up With Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          /> 
                        </div>
                      </form>
                    </div>
                    :
                    <div className="central-box">
                        <h1>Sign Up Complete</h1>
                        <div>Your account was successfully created. You will not be able to access your account
                          until you have validated your email address. <strong>Please check your inbox for a validation email, including your SPAM folder</strong> 
                        </div>
                    </div>
                  }
                </div>
              </Col>
            </Row>
        </Container>
    )
}