import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import DeviceListWizard1 from './DeviceListWizard1';
import DeviceListWizard2 from './DeviceListWizard2';
import DeviceListWizard3 from './DeviceListWizard3';
import DeviceListWizard4 from './DeviceListWizard4';
import DeviceListWizard5 from './DeviceListWizard5';


const DeviceListAddModal = ( {show, handleCloseEvent, handleAddedDeviceEvent}) =>{
  
  const [page, setPage] = useState(1);
  const [device, setDevice] = useState("");

  const handleClose = (e) => {setPage(1);handleCloseEvent(e)};

  const handleAddedDevice = (e) => {setPage(1);handleAddedDeviceEvent(e)};

  return (
            <Modal
                dialogClassName="newDeviceWizard"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
              >
            <Modal.Header closeButton>
              <Modal.Title>Add a Device</Modal.Title>
            </Modal.Header>
            {page===1 ? <DeviceListWizard1 setPage={setPage}></DeviceListWizard1>: <span></span>}
            {page===2 ? <DeviceListWizard2 setPage={setPage}></DeviceListWizard2>: <span></span>}
            {page===3 ? <DeviceListWizard3 setPage={setPage} setDevice={setDevice}></DeviceListWizard3>: <span></span>}
            {page===4 ? <DeviceListWizard4 setPage={setPage} device={device}></DeviceListWizard4>: <span></span>}
            {page===5 ? <DeviceListWizard5 setPage={setPage} handleAddedDevice={handleAddedDevice}></DeviceListWizard5>: <span></span>}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>);
}

export default DeviceListAddModal;

