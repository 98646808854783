import React, {useState} from "react";
import {Container, Button, Row, Col, Spinner} from "react-bootstrap";
import { useAuth } from "../use-auth.js";
// import 'simple-login-form/dist/index.css'
import logo from './logo.png';
import './registration.css';

export default function SendResetEmail(){
    
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
   
    let auth = useAuth();

    let sendEmail = (e) => {
        e.preventDefault();

        setShowSpinner(true);

        auth.sendPasswordResetEmail(email)
            .then(result => {
              
              if(result.success){
                setMessage(result.message);
                setErrorMessage("");
              }
              else{
                setErrorMessage(result.err);
                setMessage("");
              }
            })
            .finally( ()=>{
              setShowSpinner(false);
              }
            )
    };

    return (

      <Container fluid className="d-flex flex-column">
            <Row lg="3" className="header">
              <Col>
              <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
              </Col>
              <Col>
              </Col>
              <Col>
                <Button variant="link" className="login" href="/login">Log In</Button>
              </Col>
            </Row>
            <Row className="justify-content-lg-center content flex-fill flex-grow" lg="4">
              <Col className="d-flex flex-column flex-grow">
              <div className="h-100">
                <div className="central-box">
                  <h1>Password Reset</h1>
                  <form>
                    <div>
                      <p>Please enter your registered email address</p>
                    </div>
                    <div className="input last-input">
                    < input type="text" placeholder="Email Address" name="email" onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                    {message && <div className="message successMessage">{message}</div>}
                    {errorMessage && <div className="message errorMessage">{errorMessage}</div>}
                    <div className="bottom">
                      
                      {showSpinner ? 
                      (<Button variant="primary" disabled>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        <span className="sr-only">Sending...</span>
                        </Button>) 
                        :
                        (<button onClick={sendEmail}>Send Email</button>)
                        }

                    </div>
                  </form>
                </div>
                </div>
              </Col>
            </Row>
        </Container>
    )
}