import React, {useState} from "react";
import {Modal, Container, Row, Col, Form, Button, Alert} from "react-bootstrap";
import wizardIcon from '../images/wizard-3.png';
import s62AnimatedGif from '../images/s62-instructions.gif';
import touchscreenAnimatedGif from '../images/touchscreen-instructions.gif';
import buttonAnimatedGif from '../images/button-instructions.gif';
import venuAnimatedGif from '../images/venu-instructions.gif';

const DeviceListWizard4 = ({setPage, device}) =>{

  const [code, setCode] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const registerDevice = (code) =>{
    fetch("/api/devices/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({otid:code})
    }) // body data type must match "Content-Type" header
    .then(response => {
      if(response.status===200){
        setShowError(false);
        setPage(5);
        return "";
      }
      else{
        return response.text();
      }
    }
    )
    .then(result => {
      if(result){
        setShowError(true);
        setErrorMessage("Error creating Device. " + result);
      }
    })

  }

  return (
    <Modal.Body>
      <Container className="addPage4">
      <Row>
        <Col className="wizardImage"><img src={wizardIcon} alt="wizard step 0 indicator" /></Col>
      </Row> 
      <Row>
        <Col className="addPageTextList">
          <ol>
          <li>Ensure your watch is <strong>connected to an internet connected phone.<br/><small>Note. You may need to open Garmin Connect on iOS devices.</small></strong></li> 
          <li>Open the Mappily application menu as below (First press is a <strong>long press</strong>).</li> 
          <li>Enter the 6 digit code that appears on your device screen:</li> 
          </ol>
        </Col>
      </Row> 
      <Row lg="12" >
        <Col lg="3"></Col>
        <Col lg="6" className="codeEntry">
          <Row>
          <Col ><Form.Label>Code</Form.Label></Col>
          <Col ><Form.Control type="number" placeholder="" onChange={(e)=>{setCode(e.target.value)}} value={code}/></Col>
          <Col ><Button variant="primary" onClick={()=>registerDevice(code)}>Register Device</Button></Col>
          </Row>
        </Col>
        <Col lg="3"></Col>
      </Row>
      <Row>
        {device==="s62" ? (<Col className="addPageIcon"><img src={s62AnimatedGif} alt="wrist watch icon" className="animatedWatch" /></Col>)
        : (device==="touchscreen" ? (<Col className="addPageIcon"><img src={touchscreenAnimatedGif} alt="wrist watch icon" className="animatedWatch" /></Col>) 
        : (device==="button" ? (<Col className="addPageIcon"><img src={buttonAnimatedGif} alt="wrist watch icon" className="animatedWatch" /></Col>) 
        : (device==="venu" ? (<Col className="addPageIcon"><img src={venuAnimatedGif} alt="wrist watch icon" className="animatedWatch" /></Col>) 
        : <Col></Col>
        )))}
      </Row>
      <Row>
      <Col></Col>
      <Col>
        {showError ? 
        (<Alert key="error" variant="danger">
          {errorMessage}
        </Alert>)
      :(<p></p>)}
      </Col>
      <Col></Col>
      </Row> 
      </Container>
    </Modal.Body>
  );
}

export default DeviceListWizard4;

