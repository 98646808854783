import React, {useEffect} from "react";
import { useHistory, useLocation} from 'react-router-dom';
import _ from "lodash";

export default function StravaRedirect(){

    let history = useHistory();
    let location = useLocation();

    useEffect(()=>{

        try {
            // If not redirected to Strava, return to home
            if (_.isEmpty(location)) {
                return history.push("/home?tab=connect");
            }
        
            const searchParams = new URLSearchParams(location.search);

            if(!searchParams.has('code')){
                return history.push("/home?tab=connect");
            }
            
            const code = searchParams.get('code');

            fetch("/api/auth/strava/complete/" + code, { method: 'POST'})
            .then(response => response.status===200 ? response.json() : [])
            .then(result =>{
              console.log(result);
              history.push("/home?tab=connect");
            })

        } catch (error) {
            history.push("/home?tab=connect");
        }


    },[]);

  return (<div>loading...</div>);
}
