import React, {useState} from "react";
import {Button, Card, Modal, Alert} from "react-bootstrap";
import stravaPng from '../../images/strava.png';
import '../api-connections.css';

export default function StravaConnectionCard({apiConnection, handleRefreshConnections}){
    
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [errorMessageDisconnect, setErrorMessageDisconnect] = useState();
    const [stravaButtonText, setStravaButtonText] = useState("Sync Now");
    const [errorMessage, setErrorMessage] = useState();

    const handleShowDisconnect= () => {setShowDisconnectModal(true)};
    const handleCloseDisconnect = () => setShowDisconnectModal(false);

    const connectStrava = ()=>{
      fetch("/api/auth/strava/init")
      .then(response => response.status===200 ? response.json() : [])
      .then(result =>{
        window.location=result.redirect_uri;
      })
    }

    const disconnectStrava = ()=>{
      fetch("/api/api-connections/strava", {method: 'DELETE'})
      .then(response => {
        if(response.status===200){
          fetch("/api/auth/strava/deauthorize", {method: 'POST'}) //deauthorize the token from the UI
          setErrorMessageDisconnect();
          return "";
        }
        else{
          return response.text();
        }
      }
      )
      .then(result => {
        if(result){
          setErrorMessageDisconnect("Error disconnecting from API provider:" + result)
        }else{
          setShowDisconnectModal(false);
        }
        handleRefreshConnections();
      })
    }

    const syncStrava = ()=>{
      setStravaButtonText("Syncing....");
      fetch("/api/api-connections/strava/sync", {method: 'POST'})
        .then(response =>
          {
            console.log(response.status);
            if(response.status===200){
              setErrorMessage();
              return "";
            }else{
              return response.text();
            }
        }
        ).then (result => {
          console.log(result);
          if(result)
            setErrorMessage(result);

          setStravaButtonText("Sync Now")
        });
    }

  
    return (

        <Card className="api-connection">

          <Modal show={showDisconnectModal} onHide={handleCloseDisconnect}>
            <Modal.Header closeButton>
              <Modal.Title>Disconnect</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure?
            </Modal.Body>
            {errorMessageDisconnect !=null && errorMessageDisconnect.length >0 ? 
              (<Alert key="error" variant="danger">
                {errorMessageDisconnect}
              </Alert>)
            :(<p></p>)}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDisconnect}>
                Cancel
              </Button>
              <Button variant="primary" onClick={disconnectStrava}>
                Disconnect
              </Button>
            </Modal.Footer>
          </Modal>

          <Card.Img variant="top" src={stravaPng} />
                <Card.Body>
                  <Card.Title>Strava</Card.Title>
                  { apiConnection === null ? 
                    <Button className="connect" onClick={connectStrava}>Connect Strava</Button>
                  : <div>
                      <div className="connected-text">
                        <div>Strava is Connected</div>
                        <Button className="sync" onClick={syncStrava} disabled={stravaButtonText!=="Sync Now"}>{stravaButtonText}</Button>
                        {errorMessage !=null && errorMessage.length >0 ? 
                          (<Alert key="error" variant="danger">
                            {errorMessage}
                          </Alert>)
                          :(<React.Fragment></React.Fragment>)}
                        <div>
                          <Button className="disconnect" variant="outline-primary" size="sm" onClick={handleShowDisconnect}>Disconnect Strava</Button>
                      </div>
                      </div>
                    </div>
                    }
                </Card.Body>
        </Card>
    )
}