import React, {useState} from "react";
import { useHistory, useLocation} from 'react-router-dom';
import {Container, Button, Row, Col} from "react-bootstrap";
import { useAuth } from "../use-auth.js";
import footprintlogo from './footprint.jpg';
import logo from './logo.png';
import CookieConsent from "react-cookie-consent";
import './registration.css';
import GoogleLogin from 'react-google-login';

export default function LoginPage(){
    
    const [errorMessage, setError] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
   
    let location = useLocation();
    let auth = useAuth();
    let history = useHistory();
    let { from } = location.state || { from: { pathname: "/home" } };

    let login = (event) => {
        event.preventDefault();

        if(!username || !password){
            setError("Please enter both username and password");
            return;  
        }

        auth.signin(username, password)
            .then(result => {

              if(result.success){
                console.log("signed in User:" + result.user.username);
                history.replace(from);
              }
              else{
                setError(result.err);
              }
            })
    };
    
    const responseGoogle = (response) => {
      //console.log(response);

      auth.signinGoogle(response.tokenId)
            .then(result => {

              if(result.success){
                console.log("signed in User:" + result.user.username);
                history.replace(from);
              }
              else{
                setError(result.err);
              }
      })
    }

    return (

      <Container fluid className="d-flex flex-column" id="login-page">
            <Row lg="3" className="header">
              <Col>
              <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
              </Col>
              <Col>
              </Col>
              <Col>
              <Button variant="link" className="signUp" href="/register">Sign Up</Button>
              </Col>
            </Row>
            <Row className="justify-content-lg-center content flex-fill flex-grow" lg="4">
              <Col className="d-flex flex-column flex-grow">

              <div className="h-100">
                    <div className="central-box">
                      <div className="head-container">
                        <img src={footprintlogo} className="login-logo" alt="Mappily logo"></img>
                      </div>
                      <h1 className="login-h1">Log In</h1>
                      <form>
                        <div className="input">
                        < input type="text" placeholder="Email Address" name="email" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        </div>
                        <div className="input last-input">
                          <input type="password" placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        </div>
                        {errorMessage && <div className="message errorMessage">{errorMessage}</div>}
                        <div className="bottom">
                          <button onClick={login}>LOGIN</button>
                        </div>
                        <div className="loginformfooter">
                          <p><a href="/forgot-password">forgot password</a></p>
                        </div>
                        <div className="federated-logins">
                          <p>Or</p>
                          <GoogleLogin
                            clientId={window.MAPPILY_CONFIG.GOOGLE_CLIENT_ID}
                            buttonText="Log in with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          /> 
                        </div>
                      </form>
                    </div>
                </div>
              </Col>
            </Row>
            <CookieConsent>This website uses cookies to enhance the user experience. See our <a href="/privacy">privacy policy</a> for more details</CookieConsent>
        </Container>
    )
}