import React, {useState, useEffect} from "react";
import {Container, Card, CardDeck} from "react-bootstrap";
import RwgpsConnectionCard from './connection-cards/RwgpsConnectionCard';
import StravaConnectionCard from './connection-cards/StravaConnectionCard';

export default function ConnectionDetails(){
    
    const [apiConnections, setApiConnections] = useState();

    const fetchApiConnections = () => {
      fetch("/api/api-connections")
      .then(response => response.status===200 ? response.json() : [])
      .then(result =>{
        setApiConnections(result);
      })
    }
    
    useEffect(() => {
      fetchApiConnections();
    }, [])

    const getConnection = (name) =>{
      console.log(apiConnections);
      var connection = apiConnections && apiConnections.find(a => a.name === name);
      return connection ?? null;
    }

    return (
      <Container fluid="sm">
          <Card>
            <Card.Body>
              <Card.Title>Connections</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
              Connect applications below to automatically sync routes from that provider to Mappily.  More connections coming soon.
            </Card.Subtitle>
            <CardDeck className={getConnection('strava') === null ? "api-connections card-connections" : "api-connections  card-connections connected"}>
              <StravaConnectionCard apiConnection={getConnection("strava")} handleRefreshConnections={fetchApiConnections} />
              <RwgpsConnectionCard apiConnection={getConnection("rwgps")} handleRefreshConnections={fetchApiConnections} />
            </CardDeck>
            </Card.Body>
          </Card>
      </Container>
    )
}