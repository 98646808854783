import React, {useState, useEffect} from "react";
import {Container, Card, Row, Col, ListGroup, Alert, Button, Modal} from "react-bootstrap";
import './accountDetails.css';
import { useHistory, Link} from 'react-router-dom'
import { useAuth } from "../use-auth.js";
import crownIcon from '../images/crown.png';
import PremiumFeatures from "./PremiumFeatures";

export default function AccountDetails({highlightPayment}){
    
    const [userDetails, setUserDetails] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [errorMessageDelete, setErrorMessageDelete] = useState();
    const [showPremiumFeaturesModal, setShowPremiumFeaturesModal] = useState();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [errorMessageCancel, setErrorMessageCancel] = useState();

    let history = useHistory();
    let auth = useAuth();
    
    const fetchUserDetails = () => {
        fetch("/api/users")
        .then(response => response.status===200 ? response.json() : [])
        .then(result =>{
          setUserDetails(result);
        })
    }

    const handleShowDelete = (id) => {setShowDeleteModal(true)};
    const handleCloseDelete = () => setShowDeleteModal(false);

    const handleShowPremiumFeaturesModal = () => setShowPremiumFeaturesModal(true);
    const handleClosePremiumFeaturesModal = () => setShowPremiumFeaturesModal(false);

    const handleDelete = () => {
      fetch("/api/users",{
        method:'DELETE'
      }
      )
      .then(response => {
        if(response.status===200){
          setErrorMessageDelete();
          return "";
        }
        else{
          return response.text();
        }
      }
      )
      .then(result => {
        if(result){
          setErrorMessageDelete("Error deleting your account. Please contact user-support@mappily.info. " + result)
        }
        else{
          setShowDeleteModal(false);
          auth.signout(() => history.push("/"));
        }
      })
  }

  const handleShowCancel = (id) => {setShowCancelModal(true)};
  const handleCloseCancel = () => setShowCancelModal(false);

  const cancelSubscription = () => {
    fetch("/api/payment/paypal/subscription",{
      method:'DELETE'
    })
    .then(response => {
      if(response.status===200){
        setErrorMessageCancel();
        return "";
      }
      else{
        return response.text();
      }
    }
    )
    .then(result => {
      if(result){
        setErrorMessageCancel("Error cancelling your account. Please contact user-support@mappily.info. " + result)
      }
      else{
        setShowCancelModal(false);
        fetchUserDetails();
      }
    })
}

  const upgradeClass =() =>{
    return highlightPayment !==null ? highlightPayment + " upgrade" : "upgrade";
  }

  const getAccountTypeForDisplay = () => {
    
    if(userDetails.isPaid){
      return <span className="premium">Premium<img src={crownIcon} alt="crown icon" title="Premium User" /></span>;
    }
    else if(userDetails.remainingTrialDays ===-1 || userDetails.remainingTrialDays >0){
      return <span>Free Trial</span>;
    }
    else if (userDetails.remainingTrialDays === 0) {
      return <span>Free Trial Expired</span>;
    }
  };

  const subscriptionIsExpired = () =>{
    return userDetails.remainingSubscriptionDays!=null && userDetails.remainingSubscriptionDays <0;
  }

  const getAccountExpiryForDisplay = () => {
    
    if(userDetails.isPaid){
      if(userDetails.subscription_expires_timestamp){
        if(userDetails.subscription_status==="cancelled"){
          return <span class="expiry-content">{userDetails.remainingSubscriptionDays} days remaining (renewal cancelled)</span>;
        }else{
          return <span class="expiry-content">{userDetails.remainingSubscriptionDays} days remaining</span>;
        }
      }else{
        return <span class="expiry-content">Never Expires</span>
      }
    }
    else if(subscriptionIsExpired()){
      return <span class="expiry-content">Expired {-1* userDetails.remainingSubscriptionDays} days ago</span>;
    }
    else if(userDetails.remainingTrialDays ===-1){
      return <span class="expiry-content">Never expires</span>;
    }
    else if (userDetails.remainingTrialDays > 0) {
      return <span class="expiry-content">{userDetails.remainingTrialDays} days remaining</span>;
    }
    else if (userDetails.remainingTrialDays === 0) {
      return <span class="expiry-content">Free Trial Expired</span>;
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [])

  const renderCancelButton = () =>{
    if(userDetails.subscription_expires_timestamp!==null && (userDetails.subscription_status !=="cancelled")){
      return <Button variant="link" size="sm" onClick={handleShowCancel}>Cancel Subscription</Button>;
    }else{
      return <span></span>
    }
  }

  const paymentTitle = () => {
    return userDetails.subscription_status==="cancelled" || subscriptionIsExpired() ?
    "Renew Membership"
    : "Upgrade to Premium";
  }

  const paymentSubtitle = () => {
    return userDetails.subscription_status==="cancelled" || subscriptionIsExpired()?
    <Card.Subtitle className="mb-2">
              You can renew at any time, or purchase lifetime access. 
              <br/>
              <br/>
              Renewing your subscription before the renewal date will extend it for another year, starting from the date of purchase.
    </Card.Subtitle>
    : 
    <Card.Subtitle className="mb-2">
      All users get a 30 day trial of 
      <Button variant="link" className="button-all-features" onClick={()=> handleShowPremiumFeaturesModal()} >all features</Button>
      . Upgrade at any time for Premium access. We have two payment options:
    </Card.Subtitle>;
  }

  const renderPaymentCard = () => {
    
      if(userDetails!==null && (!userDetails.isPaid || userDetails.subscription_status==="cancelled")){
        return <Card className={upgradeClass()}>
          <Card.Body>
            <Card.Title>{paymentTitle()}</Card.Title>
            {paymentSubtitle()}

            <Card>
              <Card.Body>
                <Card.Title>Lifetime Access</Card.Title> 
                <Card.Text>If you would like Lifetime access for a one-off fee this one is for you</Card.Text> 
                <Card.Text className="price">
                  <span>price:</span>£{window.MAPPILY_CONFIG.ONE_OFF_COST}<Link to={{
                      pathname: '/payment',
                      state: [{action: 'oneoff'}]
                    }}> Buy Now </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Yearly Subscription</Card.Title> 
                <Card.Text>Pay yearly for as long as you want to use the app, Subscription will automatically renew. Cancel at any time.</Card.Text> 
                <Card.Text className="price">
                  <span>price:</span>£{window.MAPPILY_CONFIG.SUBSCRIPTION_COST}<Link to={{
                      pathname: '/payment',
                      state: [{action: 'subscribe'}]
                    }}> Buy Now </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      }
      else{
        return null;
      }
    };

    return (
          <Container fluid="sm" className="account-details">
            
            <Modal dialogClassName="premium-modal" show={showPremiumFeaturesModal} onHide={handleClosePremiumFeaturesModal} 
            size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Free versus Premium</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PremiumFeatures/>    
              </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Deleting Account</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This will <strong>PERMANENTLY</strong> delete your account and all your routes. Even if you have a paid account
                you will no longer have any access and your personal data will be deleted. You will be immediately logged out.
                <br/>
                <br/>
                Are you sure?
              </Modal.Body>
              {errorMessageDelete !=null && errorMessageDelete.length >0 ? 
                (<Alert key="error" variant="danger">
                  {errorMessageDelete}
                </Alert>)
              :(<p></p>)}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDelete}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showCancelModal} onHide={handleCloseCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Cancelling Subscription</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This will cancel your subscription at renewal (starting from the next renewal date). You will still have access until that date. You 
                can re-subscribe at any time. 
                <br/>
                <br/>
                Are you sure?
              </Modal.Body>
              {errorMessageCancel !=null && errorMessageCancel.length >0 ? 
                (<Alert key="error" variant="danger">
                  {errorMessageCancel}
                </Alert>)
              :(<p></p>)}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCancel}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={cancelSubscription}>
                  Continue
                </Button>
              </Modal.Footer>
            </Modal>

            <Row className="justify-content-md-center" lg="1">
              <Col>
                <Card>
                  <Card.Body>
                  <Card.Title>Details</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>                  
                  {userDetails !=null
                    ?
                  (
                    <Container>
                      <Row>
                        <Col lg="6">
                          <ListGroup variant="flush" className="user-profile">
                            <ListGroup.Item>
                              <span className="title">Username:</span>{userDetails.username}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <span className="title">Account Type:</span>
                              {getAccountTypeForDisplay()}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <span className="title">Subscription Expiry:</span>
                              {getAccountExpiryForDisplay()}
                              {renderCancelButton()}
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                        <Col lg="6">
                         {renderPaymentCard()}
                        </Col>
                      </Row>
                    </Container>
                    ) 
                  :
                  (<p className="no-devices">Something went wrong loading your user details. Please refresh the page</p>)} 
                  </Card.Body>
                  <Card.Footer className="delete-account">
                    <Button variant="secondary" size="sm" onClick={handleShowDelete}>Permanently Delete My Account</Button>
                  </Card.Footer>
                </Card>
            </Col>
            </Row>
          </Container>);
}