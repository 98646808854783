import React, {useState, useEffect} from "react";
import {Container, InputGroup, FormControl, Button, Card, Row, Image,
  Col, Modal, Alert, Tabs, Tab, Table, Accordion} from "react-bootstrap";
import RouteListRowItem from "./RouteListRowItem";
import newWindowIcon from '../images/new-window.png';
import CustomAccordionToggle from './CustomAccordionToggle';

import './routelist.css';

export default function RouteList(){
    
    const [routes, setRoutes] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [message, setMessage] = useState();
    const [isSuccess, setIsSuccess] = useState();
    const [errorMessageDelete, setErrorMessageDelete] = useState();
    const [rideWithGpsUrl, setRideWithGpsUrl] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(false);
    const [isEditSuccess, setEditIsSuccess] = useState(false);
    const [editMessage, setEditMessage] = useState(false);
    const [routeLimit, setRouteLimit] = useState(50);
    const [routeTotalCount, setRouteTotalCount] = useState(0);

    useEffect(() => {
      fetchRoutes();
    },[routeLimit])

    const incrementLimit = () =>{
      var limit = routeLimit;
      setRouteLimit(limit + 50);
    }

    const handleDelete = () => {
      fetch(`/api/routes/${selectedRoute}` , {
        method: 'DELETE'
      })
      .then(response => {
        if(response.status===200){
          setErrorMessageDelete();
          return "";
        }
        else{
          return response.text();
        }
      }
      )
      .then(result => {
        if(result){
          setErrorMessageDelete("Error deleting route. " + result)
        }
        else{
          setShowDeleteModal(false);
          fetchRoutes();
        }
      })
    }

    const handleShowDelete = (id) => {setSelectedRoute(id);setShowDeleteModal(true)};
    const handleCloseDelete = () => setShowDeleteModal(false);

    const fetchRoutes = () =>{
      console.log(`get ${routeLimit} routes`)
      fetch(`/api/routes?limit=${routeLimit}&sort=date-desc`)
        .then(response => {
          setRouteTotalCount(response.headers.get("X-total-count"));
          return response.status===200 ? response.json() : []
        })
        .then(result =>{
          setRoutes(result);
        })
    }

    const changeHandler = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    const urlChangeHandler = (event) =>{
      setRideWithGpsUrl(event.target.value)
    }

    const handleSubmission = () => {
      const formData = new FormData();

      formData.append('routeFile', selectedFile);

      fetch(
          '/api/routes',
          {
            method: 'POST',
            body: formData
          }
        )
        .then((response) => {
            console.log(response);
            if(!response.ok){
              if(response.status===413){
                throw new Error("The route file was too large. Please ensure it is under 10MB");
              }
              else{
                throw new Error("Unexpected Error occurred. Please contact user-support@mappily.info: Status" + response.status);
              }
            }
            return response.json()
          }
        )
        .then(handleUploadResult)
        .catch(handleError);
    };

    const handleUrl = () => {

      fetch(
          '/api/routes',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({url:rideWithGpsUrl})
          }
        )
        .then((response) => {
          return response.json();
        })
        .then(handleUploadResult)
        .catch(handleError);
    };

    const handleError = (error) =>{
        console.error('Error:', error.message);
        setMessage(error.message);
        setIsSuccess(false);
    };

    const handleEditError = (error) =>{
      
      if(error){
        console.error('Error:', error);
        setEditMessage(error);
        setEditIsSuccess(false);
      }
      else{
        setEditMessage("");
        setEditIsSuccess(true);
      }
    };

    const handleUploadResult = (result) =>{
      
        console.log(result);
        if(!result.success){
          setMessage(result.err);
          setIsSuccess(false);
        }
        else{
          setMessage(`Successfully created route ${result.route.name}`);
          setIsSuccess(true);
        }

        fetchRoutes();
    }

    const sortByDateDesc = (a, b) => { return Date.parse(b.created_at) - Date.parse(a.created_at)}
    const sortBySortOrderDesc = (a, b) => { return b.sort_order - a.sort_order}

     return (
          <Container fluid="sm" className="route-list">
            
            
            <Modal show={showDeleteModal} onHide={handleCloseDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Deleting Route</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p><strong>Are you sure?</strong></p>
                <p>Note: if this route was sync'd from a provider such as Strava it will be reimported. Delete it at source to prevent this.</p>
              </Modal.Body>
              {errorMessageDelete !=null && errorMessageDelete.length >0 ? 
                (<Alert key="error" variant="danger">
                  {errorMessageDelete}
                </Alert>)
              :(<p></p>)}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDelete}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <Row className="route-upload-choice">
              <Col>
              <Accordion>
                <Card>
                  <Card.Header>
                    <CustomAccordionToggle title="Upload a Route" eventKey="1">
                      Upload a Route
                      {/* <Image src={accordionArrow} alt="Arrow"></Image> */}
                    </CustomAccordionToggle>
                    </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    {message!=null && message.length>0 ? 
                      (<Alert  key="upload-message" variant={isSuccess ? "success" : "danger"}>
                        {message}
                      </Alert>)
                    :(<p></p>)}
                      <Tabs id="route-upload" defaultActiveKey="#upload">
                        <Tab title="GPX File" eventKey="#upload">
                          <Card className="upload-card">
                            <Card.Subtitle className="mb-2 text-muted">Load a route from a <strong>.gpx</strong> file</Card.Subtitle>
                            <div className="file-chooser">
                              <input type='file' name="routeFile" accept=".gpx" onChange={changeHandler}/><br/>
                            </div>
                            <Button variant="primary" className="mt-3" onClick={handleSubmission}>Upload</Button>
                          </Card>
                        </Tab>
                      
                        <Tab title="Ride With GPS" eventKey="#ridewithgps">
                          <Card className="upload-card">
                            <Card.Subtitle className="mb-3 text-muted">Paste a public <a target="_blank" rel="noreferrer" href="www.ridewithgps.com">RideWithGps</a><img className="newWindow" src={newWindowIcon} alt="Opens in a new window" /> Route Url below to import a route directly</Card.Subtitle>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1" >Url</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                              placeholder="https://ridewithgps.com/routes/..."
                              aria-label="https://ridewithgps.com/routes/..."
                              aria-describedby="basic-addon1"
                              value={rideWithGpsUrl} onChange={urlChangeHandler}
                              />
                            </InputGroup>
                            <Button variant="primary" className="upload" onClick={handleUrl}>Import</Button>
                          </Card>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
            <Row className="route-card">
              <Col>
                <Card.Title>Your Routes</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">To load and follow a route, open the Menu on the Device Map screen and select 'Load Route'</Card.Subtitle>
                {editMessage!=null && editMessage.length>0 ? 
                (<Alert  key="upload-message" variant={isEditSuccess ? "success" : "danger"}>
                  {editMessage}
                </Alert>)
              :(<p></p>)}
                {routes !=null && routes.length > 0 
                          ?
                        (<React.Fragment>
                            <Table hover className="routes">
                            <tbody>
                            {routes.map(item => 
                                (
                                <RouteListRowItem key={item.id} item={item} handleShowDelete={handleShowDelete} handleError={handleEditError}/>
                                )
                              )
                            }
                            </tbody>
                          </Table>
                          <div id="more-routes-button">
                            <p> {Math.min(routeLimit, routeTotalCount)} of {routeTotalCount}</p>
                            { routeLimit <= routeTotalCount ?
                            <Button onClick={incrementLimit}>Load More</Button>
                            : <span></span>
                            }
                          </div>
                        </React.Fragment>) 
                        :
                        (<p>You have no routes. You can add one using the Upload button above</p>)} 
              </Col>
                {/*  */}
            </Row>
          </Container>);
}