import React, {useState} from "react";
import {Button, Card, Modal, Alert, Form, Col, Container, Row} from "react-bootstrap";
import rwgpsPng from '../../images/rwgps.png';
import '../api-connections.css';

export default function RwgpsConnectionCard({apiConnection, handleRefreshConnections}){
    
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [errorMessageDisconnect, setErrorMessageDisconnect] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [errorMessageConnect, setErrorMessageConnect] = useState();
    const [rwgpsButtonText, setRwgpsButtonText] = useState("Sync Now");
    const [showUsernamePasswordModal, setShowUsernamePasswordModal] = useState(false);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleShowDisconnect= () => {setShowDisconnectModal(true)};
    const handleCloseDisconnect = () => setShowDisconnectModal(false);

    const connectRwgps = ()=>{
      fetch("/api/auth/rwgps/init", 
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json' },
          body: JSON.stringify(
            {
              email:email, 
              password:password
            }
          )
        }
      )
      .then(response => {
        if(response.status===200){
          setErrorMessageConnect();
          return "";
        }
        else{
          return response.text();
        }
      })
      .then(result => {
        if(result){
          setErrorMessageConnect("Error connecting API provider: " + result)
        }else{
          setShowUsernamePasswordModal(false);
        }
        handleRefreshConnections();
      })
    }

    const disconnectRwgps = ()=>{
      fetch("/api/api-connections/rwgps", {method: 'DELETE'})
      .then(response => {
        if(response.status===200){
          fetch("/api/auth/rwpgs/deauthorize", {method: 'POST'}) //deauthorize the token 
          setErrorMessageDisconnect();
          return "";
        }
        else{
          return response.text();
        }
      }
      )
      .then(result => {
        if(result){
          setErrorMessageDisconnect("Error disconnecting from API provider:" + result)
        }else{
          setShowDisconnectModal(false);
        }
        handleRefreshConnections();
      })
    }

    const syncRwgps = ()=>{
      setRwgpsButtonText("Syncing....");
      fetch("/api/api-connections/rwgps/sync", {method: 'POST'})
        .then(response =>
          {
            if(response.status===200){
              setErrorMessage();
              return "";
            }else{
              return response.text()
            }
        }
        ).then (result => {
          if(result)
            setErrorMessage(result);

          setRwgpsButtonText("Sync Now")
        });
    }
  
    return (

        <Card className="api-connection">

          <Modal show={showDisconnectModal} onHide={handleCloseDisconnect}>
            <Modal.Header closeButton>
              <Modal.Title>Disconnect</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure?
            </Modal.Body>
            {errorMessageDisconnect !=null && errorMessageDisconnect.length >0 ? 
              (<Alert key="error" variant="danger">
                {errorMessageDisconnect}
              </Alert>)
            :(<p></p>)}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDisconnect}>
                Cancel
              </Button>
              <Button variant="primary" onClick={disconnectRwgps}>
                Disconnect
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showUsernamePasswordModal} onHide={() => setShowUsernamePasswordModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Connection Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please provide your RGPS username and password to link your account:
              <Container>
                <Row>
                  <Col ><Form.Label>Email/Username</Form.Label></Col>
                  <Col ><Form.Control type="text" placeholder="username" onChange={(e)=>{setEmail(e.target.value)}} value={email}/></Col>
                </Row>
                <Row>
                  <Col ><Form.Label>Password</Form.Label></Col>
                  <Col ><Form.Control type="password" placeholder="password" onChange={(e)=>{setPassword(e.target.value)}} value={password}/></Col>
                </Row>
              </Container>
            </Modal.Body>
            {errorMessageConnect !=null && errorMessageConnect.length >0 ? 
              (<Alert key="error" variant="danger">
                {errorMessageConnect}
              </Alert>)
            :(<p></p>)}
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowUsernamePasswordModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={connectRwgps}>
                Connect
              </Button>
            </Modal.Footer>
          </Modal>

          <Card.Img variant="top" src={rwgpsPng} />
          <Card.Body>
            <Card.Title>Ride With GPS</Card.Title>
              { apiConnection === null ? 
              <Button className="connect" onClick={() => setShowUsernamePasswordModal(true)}>Connect Ride With GPS</Button>
            : <div>
                <div className="connected-text">
                  <div>Ride With GPS is Connected</div>
                  <Button className="sync" onClick={syncRwgps} disabled={rwgpsButtonText!=="Sync Now"}>{rwgpsButtonText}</Button>
                  {errorMessage !=null && errorMessage.length >0 ? 
                  (<Alert key="error" variant="danger">
                    {errorMessage}
                  </Alert>)
                  :(<React.Fragment></React.Fragment>)}
                  <div>
                    <Button className="disconnect" variant="outline-primary" size="sm" onClick={handleShowDisconnect}>Disconnect Ride With GPS</Button>
                </div>
                </div>
              </div>
              }
          </Card.Body>
        </Card>
    )
}