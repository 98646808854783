import React, {useState, useEffect} from "react";
import {useLocation, Link, useHistory} from 'react-router-dom';
import {Container, Button, Row, Col, Card, Alert} from "react-bootstrap";
import logo from './logo.png';
import './payment.css';

export default function PaymentPage(){
   
    const [sdkReady, setSdkReady] = useState(false);
    const [showPaymentResult, setShowPaymentResult] = useState(false);
    const [paymentResult, setPaymentResult] = useState(null);

    let location = useLocation();
    let history = useHistory();

    console.log(location);
    const isSubscription = location.state!==null && location.state.length>0 && location.state[0].action==='subscribe';
    console.log("isSubscription=" + isSubscription);

    const addPaypalSdk = () => {

      var scriptId = "paypal-sdk-script";
      var existingScript = document.getElementById(scriptId);
      
      if(existingScript)
      {
        if(existingScript.dataInfo === isSubscription)
          return;
        else
          existingScript.remove();
      }

      const clientID = window.MAPPILY_CONFIG.PAYPAL_CLIENT_ID;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = scriptId;
      
      script.dataInfo = isSubscription;
            
      if(isSubscription)
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&intent=subscription&vault=true`;
      else
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&components=buttons&currency=GBP`;

      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      script.onerror = () => {
        throw new Error('Paypal SDK could not be loaded.');
      };

      document.body.appendChild(script);
  };



  useEffect(() => {
    if (window !== undefined) {
      console.log("added SDK")
      addPaypalSdk();
    } else if (
      window !== undefined &&
      window.paypal !== undefined 
    ) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basketDescription =() =>{
    return isSubscription ? "1 Year Subscription. Renews automatically" 
                          : "One Off Payment for lifetime access";
  }

  const basketCost =() =>{
    return "£" + (isSubscription ? window.MAPPILY_CONFIG.SUBSCRIPTION_COST 
                                : window.MAPPILY_CONFIG.ONE_OFF_COST) ;
  }

  useEffect(() =>{

    console.log("sdkReady=" + sdkReady);
    if(!sdkReady)
      return;

    const enableOneOffButtons = () => {

      console.log("enabling one-off buttons");
      window.paypal.Buttons({
          createOrder: function() {
            return fetch('/api/payment/paypal', {
              method: 'post',
              headers: {
                'content-type': 'application/json'
              }
            }).then(function(res) {
              return res.json();
            }).then(function(data) {
              return data.orderId;
            });
          },
          onApprove: function(data) {
            return fetch('/api/payment/paypal', {
              method: 'put',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify(data)
            }).then(function(res) {
              return res.json();
            }).then(function(details) {
              setPaymentResult(details);
              setShowPaymentResult(true);
            })
          }
      }).render('#paypal-button-container');
    }

    const enableSubscriptionButtons = () => {
      console.log("enabling subscription buttons");
      window.paypal.Buttons({
          style: {
            shape: 'rect',
            layout: 'vertical',
          },
          createSubscription: function(data, actions) {
            return fetch('/api/payment/paypal/subscription', {
              method: 'post',
              headers: {
                'content-type': 'application/json'
              }
            }).then(function(res) {
              return res.json();
            }).then(function(data) {
              return data.id;
            });
          },
          onApprove: function(data) {
            return fetch('/api/payment/paypal/subscription', {
              method: 'put',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify(data)
            }).then(function(res) {
              return res.json();
            }).then(function(details) {
              setPaymentResult(details);
              setShowPaymentResult(true);
            })
          }
        }).render('#paypal-button-container');
    }

    if(isSubscription)
      enableSubscriptionButtons();
    else
      enableOneOffButtons();
  

    }, [sdkReady, isSubscription, history]);

    const successMessage = () =>{
      return <React.Fragment>
        <div class="successMessage">
          Congratulations and thanks for signing up. You now have Premium Membership.
        </div>
        <div class="successMessage">
          We have sent you an email confirming your purchase
        </div>
      </React.Fragment>
    }

    const renderPaymentCard = () => {
    
      if(showPaymentResult){
        return  <Card className="upgrade">
        <Card.Body>
          <Card.Title>{paymentResult.isSuccess ? "Upgrade Successful" : "Upgrade Failed"}</Card.Title>
          <Card.Subtitle className="mb-2"></Card.Subtitle>
          <Card.Text className="paypal">
            <Alert variant={paymentResult.isSuccess ? "success" : "danger"}>
              {paymentResult.message}
            </Alert>
          </Card.Text>
          <Card.Text>
            {paymentResult.isSuccess ? 
                successMessage()
                : ""}
            <div class="successMessage">
              <a href="/home?tab=account">Return to Account Page</a>
            </div>
          </Card.Text>
          </Card.Body>
        </Card>
      }
      else{

        return <React.Fragment>
          <Card className="basket">
            <Card.Body>
              <Card.Title>Your Basket</Card.Title>
              <Card.Subtitle className="mb-2"></Card.Subtitle>
              <Card.Text>
                {basketDescription()}
              </Card.Text>
              <Card.Text className="price">
                {basketCost()}
              </Card.Text>
            </Card.Body>
            </Card>
            <Card className="payment">
            <Card.Body>
              <Card.Title>Payment</Card.Title>
              <Card.Subtitle className="mb-2"></Card.Subtitle>
              <Card.Text>
                Please choose a payment method below
              </Card.Text>
              <Card.Text className="paypal">
                <div id="paypal-button-container"></div>
              </Card.Text>
            </Card.Body>
        </Card>
      </React.Fragment>
      }
    };

    return (

      <Container fluid className="d-flex flex-column payment-page">
            <Row lg="3" className="header">
              <Col>
              <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md="auto">
                <a href="/home?tab=account">Back</a>
                {renderPaymentCard()}
              </Col>
            </Row>
        </Container>
    )
}