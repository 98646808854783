import React, {useState} from "react";
import {FormControl, Button, Modal} from "react-bootstrap";
import PencilIcon from './pencil.png';
import StarIcon from '../images/star.png';
import StarIconOutline from '../images/star-outline.png';
import MagnifyIcon from '../images/magnify.png';
import noThumbnail from '../images/missing-thumbnail.png';
import newWindowIcon from '../images/new-window.png';

export default function RouteListRowItem({item, editing, handleShowDelete, handleError}){
      
  const [isEditing, setIsEditing] = useState(editing);
  const [updatedRouteName, setUpdatedRouteName] = useState(item.name);
  const [showMapImage, setShowMapImage] = useState(false);
  const [retryCounter, setRetryCounter] = useState(0);
  const [isFavourite, setIsFavourite] = useState(item.is_favourite);

  const handleCloseMapImage = () => setShowMapImage(false);
  const handleShowMapImage = () => setShowMapImage(true);

  const handleNameChange = (e) => {setUpdatedRouteName(e.target.value)};
  
  const handleEdit = () => {
    setIsEditing(true);
    setUpdatedRouteName(item.name);
  }

  const handleToggleFavourite = (item) =>{
    
    fetch('/api/routes/' + item.id,
      {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({id: item.id, is_favourite : !isFavourite })
      }
    ).then((response) => {
      if (response.ok) {
        var favourite = isFavourite;
        setIsFavourite(!favourite);
      }
    });
  }

  const handleSaveEdit = () =>{
    fetch(
      '/api/routes/' + item.id,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id : item.id, name: updatedRouteName })
      }
    )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response.text();
      }
    })
    .then(handleEditingRouteResponse)
    .catch((error) => {error.then(text => handleError(text))});
  }

  const handleEditingRouteResponse = (route) =>{
    item.name=route.name;
    setIsEditing(false);
    handleError("");
  }

  function routeLength(lengthLengthM){
    return lengthLengthM==null ? "" : (lengthLengthM/1000).toFixed(1) + "km";
  }

  function handleThumbnailError (e, thumbnail_url){
    e.target.onerror=null;
    e.target.src = noThumbnail;
    e.target.className="missing";
    retryImageRequest(e.target, thumbnail_url);
  }

  function retryImageRequest(image, thumbnail_url){
    setTimeout(()=>
      {
        if(retryCounter>2)
          return;
        
        setRetryCounter(retryCounter + 1);
        image.src = thumbnail_url;
      }, 
      5000);
  }

  if(isEditing){
    return <tr>
      <td colspan="3">
          <FormControl
          className="name-edit"
          placeholder="route name"
          aria-label="Username"
          aria-describedby="basic-addon1"
          defaultValue ={updatedRouteName}
          onChange={handleNameChange}
          />
          <Button variant="link" className="save" onClick={()=> handleSaveEdit()} >save</Button>
      </td>
    </tr>
  }

  return <tr>
            <td className="route-name">
              {item.name}
              <Button variant="link" className="edit" onClick={()=> {handleEdit()}} >
                <img alt="edit" title="Edit" src={PencilIcon}></img>
              </Button>
              <Button variant="link" className="favourite" onClick={()=> {handleToggleFavourite(item)}} >
                <img alt="favourite" title="Favourite" src={isFavourite ? StarIcon : StarIconOutline}></img>
              </Button>
            </td>
            <td className="route-distance">
              <span className="route-length">{routeLength(item.length_m)}</span>
            </td>
            <td className="route-image">
              <Modal dialogClassName="map-image-modal" size='lg' centered show={showMapImage} onHide={handleCloseMapImage}>
                <Modal.Header closeButton>
                  <Modal.Title>Route Map.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <img alt="route map" src={item.image_url}></img>
                </Modal.Body>
                {item.source_url!=null ?
                (<Modal.Footer>
                  <a href={item.source_url} target="_blank" rel="noreferrer">{item.source_url}</a>
                  <img className="newWindow" src={newWindowIcon} alt="Opens in a new window" />
                </Modal.Footer>) 
                : (<Modal.Footer></Modal.Footer>)}
              </Modal>
              <div className="route-thumbnail">
                {item.source_name!==null ? 
                  <div className="source-overlay">{item.source_name}</div>
                  : <React.Fragment></React.Fragment>
                }
                <img className="thumbnail" alt="route thumbnail" src={item.thumbnail_url} 
                                      onClick={handleShowMapImage} 
                                      onError={(e) => {handleThumbnailError(e, item.thumbnail_url)}}/>
                <img className="overlay" alt="magnifying glass" src={MagnifyIcon} onClick={handleShowMapImage}></img>
              </div>
            </td>
            <td className="route-delete">
              <Button variant="link" className="delete" onClick={()=> handleShowDelete(item.id)} >delete</Button>
            </td>
        </tr> ;
}
