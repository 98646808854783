import React, { useState, useContext, createContext, useEffect } from "react";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(localStorage.getItem("user") || null);

  const checkAuth= () => { 
      return fetch('/api/auth')
                .then(response => response.json())
                .then(result =>{
                  setUser(result.success ? result.user.username : false);
                  return result;
                })
  }

  useEffect(
    () => {  
      checkAuth();
    }
  ,[]);

  // ... to save the user to state.
  const signin = (email, password) => {
      console.log("signing in: "  + email)
      return fetch('/api/auth/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({username:email, password: password})
          })
          .then(response => response.json())
          .then(result => {
            if(result.success){
              setUser(result.user.username);
              localStorage.setItem("user", result.user.username)
            }
            return result;
          })
  };

  const signinGoogle = (id_token) => {

    return fetch('/api/auth/google', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({token:id_token})
        })
        .then(response => response.json())
        .then(result => {
          if(result.success){
            setUser(result.user.username);
            localStorage.setItem("user", result.user.username)
          }
          return result;
        })
  };

  const signup = (email, password, code) => {
    console.log("creating user "  + email)
      setUser(email)
      return fetch('/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({username:email, password: password, code: code})
      })
      .then(response => response.json())
      .then(result => {return result});
  };

  const signout = () => {
    return fetch('/api/auth/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      console.log("logout got:" + response.status);
      setUser(null);
      localStorage.removeItem("user");
    })
  };

  const sendPasswordResetEmail = email => {
    return fetch('/api/resetemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email:email})
    })
    .then(response => response.json())
    .then(result => {return result});
  };

  const verifyEmail = token => {
    console.log("verifying email with token " + token)
    return fetch('/api/verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({token:token})
    })
    .then(response => response.json())
    .then(result => {return result});
  };

  const confirmPasswordReset = (token, password) => {
    return fetch('/api/password', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
          {
            password:password,
            token:token
          }
        )
    })
    .then(response => response.json())
    .then(result => {return result});
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
//   useEffect(() => {
//     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//       if (user) {
//         setUser(user);
//       } else {
//         setUser(false);
//       }
//     });

//     // Cleanup subscription on unmount
//     return () => unsubscribe();
//   }, []);
  
  // Return the user object and auth methods
  return {
    user,
    signin,
    signinGoogle,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    verifyEmail,
    checkAuth
  };
}