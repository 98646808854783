import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';
  import {Form} from "react-bootstrap";
  import React from 'react';
  
  const CaptchaButton = ({ onVerifyCaptcha }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const clickHandler = async () => {
      if (!executeRecaptcha) {
        return;
      }
  
      const token = await executeRecaptcha('contact');
  
      onVerifyCaptcha(token);
    };
  
    return (
      <Form.Check type="checkbox" className="captcha-checkbox" label="I am human" onClick={clickHandler}>
      </Form.Check>
    );
  };
  
  export const ReCaptcha = ({ onVerifyCaptcha }) => (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Ld6sx4bAAAAABB7BIst9gzUxWiRXmgaYfz_CXUy"
    >
      <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
    </GoogleReCaptchaProvider>
  );