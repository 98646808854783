import React from "react";
import {Modal, Container, Row, Col, Button} from "react-bootstrap";
import watchIcon from '../images/watch-icon.jpg';
import wizardIcon from '../images/wizard-0.png';

const DeviceListWizard1 = ({setPage}) =>{
  
    return (
      <Modal.Body>
        <Container className="addPage1">
        <Row>
          <Col className="wizardImage"><img src={wizardIcon} alt="wizard step 0 indicator" /></Col>
        </Row> 
        <Row>
          <Col className="addPageText">Let's register your device so you can access the maps. It'll take a couple of minutes</Col>
        </Row> 
        <Row>
          <Col className="addPageButton"><Button variant="primary" size="lg" onClick={()=>setPage(2)}>Start</Button></Col>
        </Row>
        <Row>
          <Col className="addPageButton"><Button variant="link" size="lg" onClick={()=>setPage(4)}>skip, I know my code</Button></Col>
        </Row>
        <Row>
          <Col className="addPageIcon"><img src={watchIcon} alt="wrist watch icon" /></Col>
        </Row>
        <Row>
          <Col className="addPageTextLower">Don't worry if you don't want to do this right now. You can do it at any time from the Devices tab</Col>
        </Row> 
        </Container>
      </Modal.Body>
  );
}

export default DeviceListWizard1;

