import React from "react";
import {Container, Button, Row, Col, Accordion, Card} from "react-bootstrap";
import logo from './logo.png';
import CookieConsent from "react-cookie-consent";
import './registration.css';
import './homepage.css';
import './faqs.css';

export default function Faqs(){
       
    
    return (

      <Container fluid className="d-flex flex-column" id="faq-page">
        <Row lg="3" className="header">
          <Col>
          <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
          </Col>
          <Col>
          </Col>
          <Col>
          <Button variant="link" className="signUp" href="/register">Sign Up</Button>
          </Col>
        </Row>
        <Row className="justify-content-lg-center landing flex-fill flex-grow content" lg="2" sm="1">
          <Col className="d-flex flex-column flex-grow">
            <Card className="faqs">
              <h1>
                FAQs
              </h1>
              <p>See below for the most frequently asked questions. For more help and support either email us at <a href="mailto:user-support@mappily.info">user-support@mappily.info</a> or get help on our <a href="https://forum.mappily.info">support forum</a></p>
              <h2>General</h2>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Do I need to register to use the app?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        No, you don't. If you just want to try out the app, you can download it 
                        from the <a href="https://apps.garmin.com/en-US/apps/a64d3253-f976-405b-afdf-eef2e5dca6ec">Garmin App Store</a> for free and 
                        as soon as you have a GPS fix (go outside!) and an internet connection via your phone you will be able to use the app to load 
                        the maps and record activities.
                      </p>
                      <p>
                        You need to register after 30 days if you would like to continue using background maps. The app will
                        tell you how many days you have left when it starts up. You also need to register if you want to upload routes and navigate them, because this is done via the website.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    Does my Garmin need to already support maps to use the app?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      No, absolutely not. Mappily will work on all supported devices. This includes devices that don't have native maps (this was really 
                      the original motivation for the app).
                      </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    How much does the app cost?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      The Premium version of the app (the one with background maps) has two options. Either pay £{window.MAPPILY_CONFIG.SUBSCRIPTION_COST} yearly, or
                      £{window.MAPPILY_CONFIG.ONE_OFF_COST} for lifetime access. The subscription is managed by Paypal and will renew automatically. You can cancel at any time
                      which will cause your subscription to lapse at the end of the period you have paid for.
                      
                      The free version has most features, but no background maps. We think the app is worth the cost.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                    Do you have maps of XXX place?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>
                        Yes, unless XXX is The Arctic or Antarctica, we have you covered. Our maps contain all the transport links
                        listed on Open Street Map, including roads, trails, paths and rivers.
                      </p>
                      <p>
                        <strong>Important</strong> In certain situations certain details will not be shown. This is intentional
                        and ensures that the watch can cope with displaying the best map possible at all times. If you zoom in you may find features
                        reappear. 
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <h2>Registration and Login</h2>
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    Help, I have a problem registering
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      Either email us at <a href="mailto:user-support@mappily.info">user-support@mappily.info</a> or get help on our <a href="https://forum.mappily.info">support forum</a>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    I'm having trouble logging in or my password doesn't work
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                       First off, double check you're logging in with the email address you registered with. You will have an email to that address in your inbox from when
                       you registered with us. If that's correct, hit the 'Forgot Password' link on the login page to send a new password to your inbox.
                      </p>
                      <p>
                        Also check that you verified your email address after you registered.  You need to click on the link in the email we sent you
                        to verify that your address belongs to you. Until you do that, you won't be able to log in.  
                      </p>
                      <p>
                        If you're still stuck, either email us at <a href="mailto:user-support@mappily.info">user-support@mappily.info</a> or get 
                        help on our <a href="https://forum.mappily.info">support forum</a> and we'll sort it for you.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <h2>Using the App</h2>
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    I can't open the application menu to register my device
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>
                      This happens a lot. We've tried really hard to cover every watch but Garmin puts the menu button in a different place on every one
                      to confuse us and make life hard. Generally, if you have a touchscreen watch with one button or less, the menu is a long press on the screen, otherwise
                      it's a long press on one of the buttons on the side of the watch. If you look closely, one of them might be marked menu, otherwise
                      google "garmin *mywatch* menu button" to find which button it is. You have to <strong>long press the button or screen</strong> for a couple 
                      of seconds
                    </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    The map is blank, or some sections are missing
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        Mappily works by downloading the map sections you need on-demand from our servers. Two things need to be true for you to see a map for the first time:
                      </p>
                      <p>
                        <ol>
                          <li>Your watch must be <strong>connected to the internet</strong> either via your phone or directly. Check bluetooth/wifi/3g is on and connected</li>
                          <li>Your watch must <strong>have a GPS signal</strong>. We rely on the watch to get the GPS fix for us. If you have no fix it's likely because you're 
                            either inside (hint: look up), under cover or the GPS on your watch isn't working for some reason (it has GPS right?!).
                          </li>
                        </ol>
                      </p>
                      <p>
                        When you're out-and-about Mappily is constantly working out where you are and downloading new map sections on demand. This
                        means that if you're in a data-blackspot and move to a new part of the map and it doesn't display, the watch may be having trouble downloading that section
                        of the map. This can also be true even if you've been in that place already. Mappily is only holding the pieces of map it needs at any
                        one time. <br/>
                        <strong>Tip:</strong> use the Offline-Maps feature to load all the map sections you need along your route before you leave. 
                      </p>
                      <p>
                        Sometimes, if the map gets really complicated, the app will adaptively decide to either simplify the map, or in extreme cases of
                        low memory, temporarily stop loading map sections. This prevents the watch from running out of memory, which would cause it to crash (and
                        is pretty annoying to say the least). This happens most often on watches with very little memory, such as the ForeRunner. The route line will 
                        remain visible at all times so you can still navigate, and the map will reappear when conditions improve.
                      </p>
                      <p>
                        If you're still stuck, either email us at <a href="mailto:user-support@mappily.info">user-support@mappily.info</a> or get 
                        help on our <a href="https://forum.mappily.info">support forum</a> and we'll sort it for you.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> 
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                    My question isn't covered
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      Either email us at <a href="mailto:user-support@mappily.info">user-support@mappily.info</a> or get help on our <a href="https://forum.mappily.info">support forum</a>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Card>
          </Col>      
        </Row>
        <CookieConsent>This website uses cookies to enhance the user experience. See our <a href="/privacy">privacy policy</a> for more details</CookieConsent>
        </Container>
    )
}