import React, {useState, useEffect} from "react";
import {Container, Button, Row, Col} from "react-bootstrap";
import { useAuth } from "../use-auth.js";
// import 'simple-login-form/dist/index.css'
import logo from './logo.png';
import './registration.css';

export default function VerifyAccount(){
  
  const [message, setMessage] = useState("");
  const [errorMessage, setError] = useState("");

  let auth = useAuth();

  let verifyEmail = () => {

      var urlParams = new URLSearchParams(window.location.search);
      var token = urlParams.get('token');

      if(token===null || token.length===0){
        setError("Invalid Token");
        setMessage("");
        return;
      }

      token = token.replace(/\s/g,"+");
      
      auth.verifyEmail(token)
          .then(result => {

            if(result.success){
              setMessage("Email Address successfully verified. Please click on the top right to log in.")
              setError("");
            }
            else{
              setError(result.err);
              setMessage("");
            }
          })
  };

  useEffect(() => verifyEmail(), [])

  return (

    <Container fluid className="d-flex flex-column">
          <Row lg="3" className="header">
            <Col>
            <a href="/public"><img src={logo} className="logo" alt="Mappily"></img></a>
            </Col>
            <Col>
            </Col>
            <Col>
              <Button variant="link" className="login" href="/login">Log In</Button>
            </Col>
          </Row>
          <Row className="justify-content-lg-center content flex-fill flex-grow" lg="4">
            <Col className="d-flex flex-column flex-grow">
            <div className="h-100">
              <div className="central-box">
                <h1>Email Verification</h1>
                <form>
                  {errorMessage && <div className="message errorMessage">{errorMessage}</div>}
                  {message && <div className="message successMessage">{message}</div>}
                </form>
              </div>
              </div>
            </Col>
          </Row>
      </Container>
  )
}