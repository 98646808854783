import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import { ProvideAuth, useAuth } from "./use-auth.js";

import './App.css';
import LoginPage from './pages/Login'
import HomePage from './pages/HomePage'
import RegistrationPage from "./pages/Registration.js";
import SendResetEmail from "./pages/SendResetMail.js";
import PasswordChange from "./pages/PasswordChange.js";
import VerifyAccount from "./pages/VerifyAccount.js";
import Public from "./pages/Public.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import StravaRedirect from "./pages/StravaRedirect.js";
import PaymentPage from "./pages/Payment.js";
import Faqs from "./pages/Faqs.js";

export default function App() {
  return (
    <ProvideAuth>
      <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/public">
              <Public />
            </Route>
            <Route path="/payment">
              <PaymentPage />
            </Route>
            <Route path="/faqs">
              <Faqs />
            </Route>
            <Route path="/register">
              <RegistrationPage />
            </Route>
            <Route path="/forgot-password">
              <SendResetEmail />
            </Route>
            <Route path="/password-change">
              <PasswordChange />
            </Route>
            <Route path="/verify-account">
              <VerifyAccount />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy />
            </Route>
            <Route path="/strava-redirect" component={StravaRedirect} />
            <PrivateRoute path="/home">
              <HomePage />
            </PrivateRoute>
            <Route path="/">
              <PublicPage />
            </Route>
          </Switch>
      </Router>
    </ProvideAuth>
  );
}

class DebugRouter extends Router {
  constructor(props){
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();

  console.log("current user is " + auth.user)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/public",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function PublicPage() {
  return <Redirect
            to={{
              pathname: "/public",
              state: { from: "/home" }
            }}
          />;
}