import React from "react";
import {Modal, Container, Row, Col, Button} from "react-bootstrap";
import watchIcon from '../images/tick-icon.png';
import wizardIcon from '../images/wizard-4.png';

const DeviceListWizard5 = ({handleAddedDevice}) =>{
  
    return (
      <Modal.Body>
        <Container className="addPage5">
        <Row>
          <Col className="wizardImage"><img src={wizardIcon} alt="wizard step 5 indicator" /></Col>
        </Row> 
        <Row>
          <Col className="addPageText">Device Successfully Added</Col>
        </Row> 
        <Row>
          <Col className="addPageButton"><Button variant="primary" size="lg" onClick={handleAddedDevice}>Ok</Button></Col>
        </Row>
        <Row>
          <Col className="addPageIcon tickIcon"><img src={watchIcon} alt="wrist watch icon" /></Col>
        </Row>
        <Row>
          <Col className="addPageTextLower"></Col>
        </Row> 
        </Container>
      </Modal.Body>
  );
}

export default DeviceListWizard5;

