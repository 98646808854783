import React, {useContext} from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {Image} from "react-bootstrap";
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import classNames from 'classnames';
import accordionArrow from '../images/accordion-down.svg';

export default function CustomAccordionToggle({title, eventKey, callback}) {
  const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
  const toggleOnClick = useAccordionToggle(eventKey, () => {
    // callback(eventKey);
  });
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={classNames('accordion-toggle', {'toggle-collapsed': isCurrentEventKey})}
      onClick={toggleOnClick}
    >
      {isCurrentEventKey ? title : title} 
      <Image src={accordionArrow}></Image>
    </button>
  );
}