import React from "react";
import {Modal, Container, Row, Col} from "react-bootstrap";
import wizardIcon from '../images/wizard-2.png';

const DeviceListWizard3 = ({setPage, setDevice})  =>{
  return (
    <Modal.Body>
      <Container className="addPage3">
      <Row>
        <Col className="wizardImage"><img src={wizardIcon} alt="wizard step 0 indicator" /></Col>
      </Row> 
      <Row>
        <Col className="addPageText">Please choose your device from the list below</Col>
      </Row> 
      <Row lg="4">
        <Col ><button onClick={()=>{setDevice("venu");setPage(4);}} className="deviceButton">Venu (All Models) or Vivoactive 4<br/></button></Col>
        <Col ><button onClick={()=>{setDevice("s62");setPage(4);}} className="deviceButton">S62</button></Col>
        <Col ><button onClick={()=>{setDevice("touchscreen");setPage(4);}} className="deviceButton">Other Touchscreen Device</button></Col>
        <Col ><button onClick={()=>{setDevice("button");setPage(4);}} className="deviceButton">No Touchscreen</button></Col>
      </Row>
      <Row>
      </Row>
      <Row>
        <Col className="addPageTextLower"></Col>
      </Row> 
      </Container>
    </Modal.Body>
  );
}

export default DeviceListWizard3;

