import React from "react";
import {Modal, Container, Row, Col, Button} from "react-bootstrap";
import cloudIcon from '../images/cloud2.gif';
import newWindowIcon from '../images/new-window.png';
import wizardIcon from '../images/wizard-1.png';

const DeviceListWizard2 = ({setPage}) =>{
  return (
    <Modal.Body>
      <Container className="addPage1">
      <Row>
        <Col className="wizardImage"><img src={wizardIcon} alt="wizard step 1 indicator" /></Col>
      </Row> 
      <Row>
        <Col className="addPageText">
              First, if you haven't downloaded and installed the app, do that now.  <br/>You can get it from the 
               <a target="_blank" rel="noreferrer" href="https://apps.garmin.com/en-US/apps/a64d3253-f976-405b-afdf-eef2e5dca6ec"> Garmin Connect IQ Store</a>
               <img className="newWindow" src={newWindowIcon} alt="Opens in a new window" />
        </Col>
      </Row> 
      <Row>
        <Col className="addPageButton"><Button variant="primary" size="lg" onClick={()=>setPage(3)}>I've downloaded the App</Button></Col>
      </Row>
      <Row>
        <Col className="addPageIcon"><img src={cloudIcon} alt="download icon" /></Col>
      </Row>
      <Row>
        <Col className="addPageTextLower"></Col>
      </Row> 
      </Container>
    </Modal.Body>
  );
}

export default DeviceListWizard2;

