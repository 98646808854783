import React, {useState} from "react";
import {Container, ListGroup, Button, Card, Row, Col, Modal, Alert} from "react-bootstrap";
import DeviceListAddModal from "./DeviceListAddModal";
import './homepage.css'

export default function DeviceList({showAddModal, setShowAddModal, devices, fetchDevices}){
    
  //const [devices, setDevices] = useState([]);
  //const [showAddModal, setShowAddModal] = useState(showModal);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorMessageDelete, setErrorMessageDelete] = useState();
  const [selectedDevice, setSelectedDevice] = useState();

  const handleDelete = (e) => {
    e.preventDefault();
    fetch(`/api/devices/${selectedDevice}` , {
      method: 'DELETE'
    })
    .then(response => {
      if(response.status===200){
        setErrorMessageDelete();
        return "";
      }
      else{
        return response.text();
      }
    }
    )
    .then(result => {
      if(result){
        setErrorMessageDelete("Error deleting device. " + result)
      }
      else{
        setShowDeleteModal(false);
        fetchDevices();
      }
    })
  }

  const handleShowDelete = (id) => {setSelectedDevice(id);setShowDeleteModal(true)};
  const handleCloseDelete = () => {fetchDevices();setShowDeleteModal(false)};

  // const fetchDevices = () => {
  //     fetch("/api/devices")
  //     .then(response => response.status===200 ? response.json() : [])
  //     .then(result =>{
  //       setDevices(result);
  //     })
  // }

  // useEffect(() => {
  //   fetchDevices();
  // }, [])

  const handleShow = () => {setShowAddModal(true)}
  const handleClose = () => {setShowAddModal(false)}
  const handleAddedDevice = () => {setShowAddModal(false);fetchDevices()}

  return (
        <Container fluid="sm">
          
          <Modal show={showDeleteModal} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Deleting Device</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure?</Modal.Body>
            {errorMessageDelete !=null && errorMessageDelete.length >0 ? 
              (<Alert key="error" variant="danger">
                {errorMessageDelete}
              </Alert>)
            :(<p></p>)}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <DeviceListAddModal show={showAddModal} handleCloseEvent={handleClose} handleAddedDeviceEvent={handleAddedDevice}/>
          <Row className="justify-content-md-center" lg="1">
            <Col>
              <Card>
                <Card.Body>
                <Card.Title>Devices</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">These are the devices you have linked to your account. You must connect your device to see your
                routes on that device.</Card.Subtitle>
                <Button variant="primary" onClick={handleShow} >Add New</Button>
                
                {devices !=null && devices.length > 0
                  ?
                (<ListGroup >
                  {devices.map(item => (
                    <ListGroup.Item key={item.deviceid}>{item.name}
                    <Button variant="link" className="delete" onClick={()=> handleShowDelete(item.id)} >delete</Button>
                    <p className="deviceid">ID: {item.deviceid}</p>
                    </ListGroup.Item>
                    ))
                  }
                </ListGroup>) 
                :
                (<p className="no-devices">You have no devices. You can add one using the Add button above</p>)} 
                </Card.Body>
              </Card>
          </Col>
          </Row>
        </Container>);
}