import React, {useState, useEffect} from "react";
import {Container, Tabs,Tab, Row, Col, Button, Modal, ListGroup, Image} from "react-bootstrap";
import RouteList from "./RouteList";
import AccountDetails from "./AccountDetails";
import UserProfileBadge from './UserProfileBadge'
import DeviceList from './DeviceList';
import ConnectionDetails from './ConnectionDetails'
import './homepage.css';
import './header.css';
import logo from './logo.png';
import PremiumFeatures from "./PremiumFeatures";

export default function HomePage(){
    
  const [activeTab, setActiveTab] = useState('routes');
  const [devices, setDevices] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const [highlightClass, setHighlightClass] = useState("");
  const [showPremiumFeaturesModal, setShowPremiumFeaturesModal] = useState();
  
  const fetchDevices = () => {
    fetch("/api/devices")
    .then(response => response.status===200 ? response.json() : [])
    .then(result =>{
      setDevices(result);
    })
  }

  const fetchUserDetails = () => {
    fetch("/api/users")
    .then(response => response.status===200 ? response.json() : [])
    .then(result =>{
      setUserDetails(result);
    })
  }

  useEffect(() => {
    fetchDevices();
    fetchUserDetails();

    var params = new URLSearchParams(window.location.search);
    
    if(params.has("tab"))
      setActiveTab(params.get("tab"));
  }, [])

  useEffect(() => {
    if(!localStorage.getItem("shownDeviceAddModal") 
      && devices!=null && userDetails!=null 
      && devices.length===0 && userDetails.nologins===1
      ){
      setActiveTab("devices");  
      setShowDeviceAddModal(true);
    }
  }, [devices, userDetails])

  useEffect(() => {
    if(showDeviceAddModal){
      localStorage.setItem("shownDeviceAddModal", true);
    }
  }, [showDeviceAddModal])

  const handleShowPremiumFeaturesModal = () => setShowPremiumFeaturesModal(true);
  const handleClosePremiumFeaturesModal = () => setShowPremiumFeaturesModal(false);

  const handleUpgradeNavigate = () => {
    setActiveTab("account"); 
  
    setHighlightClass("highlight-payment");
    setTimeout(function() {
        setHighlightClass("");
    }, 1000);
  };

  return (
    <Container fluid className="vh-100 homepage">
        
         <Modal dialogClassName="premium-modal" show={showPremiumFeaturesModal} onHide={handleClosePremiumFeaturesModal} 
            size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Free versus Premium</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PremiumFeatures/>    
          </Modal.Body>
        </Modal>

        <Row lg="12" className="header">
          <Col>
            <a href="/home">
              <div id="mappily-logo">
                <Image src={logo} alt="Mappily"></Image>
              </div>
            </a>
            <ListGroup horizontal className="header-right"> 
            
            {userDetails!=null && !userDetails.isPaid ? 
              <React.Fragment>

              <ListGroup.Item className="item-buy">
                <div>
                  <Button variant="danger" className="button-buy" onClick={handleUpgradeNavigate}>Buy Premium</Button>
                </div>
                <div className="feature-comparison">
                  <Button variant="link" onClick={()=> handleShowPremiumFeaturesModal()} >Feature Comparison</Button>
                </div>
              </ListGroup.Item>

              </React.Fragment>

            : <span></span> }

              {/* <ListGroup.Item>
                <Button variant="link" href="https://forum.mappily.info">
                  Support Forum
                </Button>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <UserProfileBadge user={userDetails}/>
              </ListGroup.Item>
            </ListGroup>

         </Col>
        </Row>
        <Row className="mainTabRow justify-content-lg-center content" lg="12">
          <Col className="panel d-flex flex-column flex-grow" lg="12  ">
          <Tabs activeKey={activeTab} defaultActiveKey="routes" id="controlled-tab-example" className="tabpanel" onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="routes" title="Routes">
                <RouteList />
            </Tab>
            <Tab eventKey="devices" title="Devices">
                <DeviceList showAddModal={showDeviceAddModal} setShowAddModal={setShowDeviceAddModal} devices={devices} fetchDevices={fetchDevices}/>
            </Tab>
            <Tab eventKey="account" title="Account">
                <AccountDetails highlightPayment={highlightClass}/>
            </Tab>
            <Tab eventKey="connect" title="Connect">
              <ConnectionDetails/> 
            </Tab>
          </Tabs>
        </Col>
        </Row>
        <Row className="flex-fill flex-grow">
        <Col className="panel d-flex flex-column flex-grow content" lg="12">
        </Col>
        </Row>
    </Container>);
}